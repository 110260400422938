var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-project-type-container",style:({
    height: _vm.$isMobile ? (_vm.$mobileScreenHeight + "px") : '100vh'
  })},[(_vm.projectTypes)?[(_vm.navBg)?_c('div',{staticClass:"project-type__bg scrollObj"},_vm._l((_vm.navBg),function(bg,index){return _c('div',{key:index,staticClass:"bg__item preload",class:[index === _vm.current ? 'actived' : ''],style:({
          backgroundImage: ("url(" + bg + ")")
        }),attrs:{"data-src":bg}})}),0):_vm._e(),_c('div',{staticClass:"project-type__nav"},_vm._l((_vm.projectTypes),function(item,index){return _c('div',{key:index,staticClass:"nav__item scrollObj",attrs:{"to":{
          path: ("/projects/" + (item.id))
        }},on:{"mouseenter":function($event){return _vm.navMouseEnter(index)},"mouseleave":_vm.navMouseLeave,"click":function($event){return _vm.link(item.id)}}},[_c('div',{staticClass:"mask",on:{"mouseenter":function($event){return _vm.navMouseEnter(index)},"mouseleave":_vm.navMouseLeave}}),_c('div',{staticClass:"item__text",on:{"mouseenter":function($event){return _vm.navMouseEnter(index)},"mouseleave":_vm.navMouseLeave}},[_c('i',{style:({
              backgroundImage: ("url(" + (_vm.$isMobile ? item.icon.mobile : item.icon.pc) + ")")
            })}),_c('p',[_c('span',[_vm._v(_vm._s(item.name.en))]),_c('span',[_vm._v(_vm._s(item.name.cn))])])])])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }