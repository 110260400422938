<template>
  <div
    class="page-project-type-container"
    :style="{
      height: $isMobile ? `${$mobileScreenHeight}px` : '100vh'
    }"
  >
    <template v-if="projectTypes">
      <div class="project-type__bg scrollObj" v-if="navBg">
        <div
          class="bg__item preload"
          :class="[index === current ? 'actived' : '']"
          v-for="(bg, index) in navBg"
          :key="index"
          :style="{
            backgroundImage: `url(${bg})`
          }"
          :data-src="bg"
        ></div>
      </div>
      <div class="project-type__nav">
        <div
          class="nav__item scrollObj"
          v-for="(item, index) in projectTypes"
          :key="index"
          @mouseenter="navMouseEnter(index)"
          @mouseleave="navMouseLeave"
          :to="{
            path: `/projects/${item.id}`
          }"
          @click="link(item.id)"
        >
          <div
            class="mask"
            @mouseenter="navMouseEnter(index)"
            @mouseleave="navMouseLeave"
          ></div>
          <div
            class="item__text"
            @mouseenter="navMouseEnter(index)"
            @mouseleave="navMouseLeave"
          >
            <i
              :style="{
                backgroundImage: `url(${
                  $isMobile ? item.icon.mobile : item.icon.pc
                })`
              }"
            ></i>
            <p>
              <span>{{ item.name.en }}</span>
              <span>{{ item.name.cn }}</span>
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { imgPreload } from "@/assets/utils/common";
export default {
  name: "page-project-type",
  data() {
    return {
      current: 2
    };
  },
  computed: {
    ...mapState(["projectTypes"]),
    navBg() {
      if (this.projectTypes) {
        return this.projectTypes.map(item => item.src);
      } else {
        return null;
      }
    }
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  updated() {
    // imgPreload().then(() => {
    //   this.PAGE_LOADED();
    // });
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      this.PAGE_LOADING();
      this.$store.dispatch("getTypes").then(() => {
        imgPreload().then(() => {
          this.PAGE_LOADED();
        });
      });
    },
    navMouseEnter(index) {
      this.current = index;
    },
    navMouseLeave() {
      this.current = 2;
    },
    link(id) {
      if (Number(id) !== 5) {
        this.$router.push({
          path: `/projects/${id}`
        });
      }
    }
  }
};
</script>

<style></style>
